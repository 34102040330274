import React, { useContext } from 'react';
import { Button, Modal, Alert, Form, Input } from 'antd';

import AuthContext from "../../Context/AuthContext";

import HTTP from "../../Services/HTTPService";

const { TextArea } = Input;

export default function SubmitAppeal()
{
    const { auth: { ban }, Load } = useContext(AuthContext);

    const [open, setOpen] = React.useState(false);

    const [form] = Form.useForm();

    const Submit = React.useCallback(() =>
    {

        const formData = form.getFieldsValue();

        HTTP.post(
            '/appeals/submit-appeal',
            {
                ban: ban._id,

                ...formData
            }
        )
        .catch(console.error)
        .finally(() => Load())

        form.resetFields();

        setOpen(false);

    }, [form, ban, setOpen, Load])

    return (
        <>
            <Button type="primary" onClick={() => setOpen(!open)}>
                Begin Appeal Process
            </Button>

            <Modal
                title={"Appeal Ban"}
                open={open}
                onOk={() => Submit()}
                okText={"Submit Ban Appeal"}
                onCancel={() => setOpen(!open)}
                width={1000} 
            >
                <Alert
                    message={
                        <span>
                            You do not have to specify anthing for your appeal.  However, if you wish to provide information, it will be considered as apart of the appeal.
                        
                            <br />

                            You are currently banned for&nbsp;
                            
                            <strong>
                                "{ban?.reason}"
                            </strong>
                        </span>
                    }
                />

                <Form
                    className="pt-4"
                    form={form}
                    name="appeal-form"
                >
                    <p>
                        What server were you on? Max 300
                    </p>
                    
                    <Form.Item 
                        name="server"
                    >
                        <TextArea 
                            maxLength={300} 
                        />
                    </Form.Item>

                    <p className="pt-3">
                        Why did you do it? Max 300
                    </p>
                    
                    <Form.Item 
                        name="whyDoIt"
                    >
                        <TextArea 
                            maxLength={300} 
                        />
                    </Form.Item>

                    <p className="pt-3">
                        Why did you want to be unbanned? Max 300
                    </p>
                    
                    <Form.Item 
                        name="whyUnban"
                    >
                        <TextArea 
                            maxLength={300}
                        />
                    </Form.Item>

                    <p className="pt-3">
                        Additional Info - Anything Relevant.  Max 300
                    </p>

                    <Form.Item 
                        name="additionalInfo"
                    >
                        <TextArea
                            maxLength={300}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}