import React from "react";

import { Switch, Route } from "react-router-dom";

import AuthContext from "./Context/AuthContext";

import HTTPService from "./Services/HTTPService";

import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/Footer/Footer";
import Privacy from "./Components/Privacy/Privacy";

import MyAppeals from "./Components/Appeals/MyAppeals";
import Knowledgebase from "./Components/Knowledgebase/Knowledgebase";

import "./App.css";

export default function App() {
  const [auth, setAuth] = React.useState({});

  const Load = React.useCallback(() => {
    HTTPService.post("/profile/my-profile")
      .then((res) => {
        if (res.data.success) {
          setAuth(res.data);

          if (res?.data?.user?.steamid && !res.data?.user?.discordProfile?.id) {
            window.location.href =
              "https://appeal-api.rustlegends.com/discord-login";
          }
        }
      })
      .catch(console.error);
  }, [setAuth]);

  React.useEffect(() => Load(), [Load]);

  return (
    <AuthContext.Provider value={{ auth, Load }}>
      <NavBar />

      <Switch>
        <Route path="/knowledge-base" component={Knowledgebase} />
        <Route path="/privacy" component={Privacy} />

        <Route path="/" component={MyAppeals} />
      </Switch>

      <Footer />
    </AuthContext.Provider>
  );
}
