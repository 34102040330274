import React from 'react';

import { Link } from "react-router-dom";

import "./Footer.css";

export default function Footer()
{
    return (
        <div className="footer">
            &copy; Rust Legends Ban Appeals {new Date().getFullYear()}

            &nbsp;&nbsp;|&nbsp;&nbsp;

            <Link to="/privacy" style={{ color: "white" }}>
                Privacy Policy
            </Link>
        </div>
    )
}