import React, { useContext } from 'react';

import { Tabs, Card, Popover, Table } from 'antd';
import { Row, Col } from "react-bootstrap";

import timeAgo from "../../Services/timeAgo";

import AuthContext from "../../Context/AuthContext";
  
const columns = [
    {
        title: 'Submitted',
        dataIndex: 'submitted',
        key: 'submitted',
        render: (e) => timeAgo.format(e)
    },
    {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
        title: 'Message From Admin',
        dataIndex: 'messageFromAdmin',
        key: 'messageFromAdmin',
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state'
    }
];

const { TabPane } = Tabs;

export default function CurrentAndPastAppeals()
{
    const { auth: { appeals } } = useContext(AuthContext);

    const latestAppeal = (appeals ?? []).sort((a, b) => b.submitted - a.submitted)?.[0];

    return (
        <Row className="pt-4">
            <Col md="12">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="My Latest Appeal" key="1">
                        {latestAppeal && (
                            <Card 
                                title={"'" + latestAppeal.reason + `' submitted ${timeAgo.format(latestAppeal.submitted)}`} 
                                bordered={true} 
                                style={{ width: "100%" }}
                            >
                                <span>
                                    <strong>
                                        State:
                                    </strong>
                                    
                                    &nbsp;&nbsp;{latestAppeal.state}&nbsp;&nbsp;

                                    {latestAppeal.state === "Pending Review" && (
                                        <a href="#/">
                                            <Popover content={"Appeals take 48-72 hours to be reviewed.  Once reviewed, you will recieve a DM from our bot via Discord."}>
                                                - What does this mean?
                                            </Popover>
                                        </a>
                                    )}

                                    {latestAppeal.state === "Denied" && (
                                        <a href="#/">
                                            <Popover content={"Your appeal has been reviewed by an admin and has been denied.  If you have been given a next appeal date, you can appeal again after the date given"}>
                                                - What does this mean?
                                            </Popover>
                                        </a>
                                    )}
                                </span>
                                
                                {latestAppeal.messageFromAdmin && (
                                    <React.Fragment>          
                                        <hr />      

                                        <span>
                                            <strong>
                                                Message From Admin:
                                            </strong>
                                            
                                            &nbsp;&nbsp;{latestAppeal.messageFromAdmin}
                                        </span>
                                    </React.Fragment>
                                )}
                            </Card>
                        )}

                        {!latestAppeal && (
                            <p>None...</p>
                        )}
                    </TabPane>

                    <TabPane tab="My Previous Appeals" key="2">
                        <Table 
                            dataSource={
                                (appeals ?? [])
                                    .filter(x => x._id !== latestAppeal._id)
                                    .sort((a, b) => b.submitted - a.submitted)
                            }
                            columns={columns} 
                        />
                    </TabPane>
                </Tabs>
            </Col>
        </Row>
    );
}