import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Collapse } from "antd";

class Knowledgebase extends Component {
    state = { }

    render() 
    {   
        const { Panel } = Collapse;

        return (
            <Container fluid>
                <Row className="pt-3">
                    <Col md="12">
                        <h3>Knowledgebase</h3>

                        <hr />
                    </Col>
                </Row>

                <Row>
                    <Col md="12">
                        <Collapse defaultActiveKey={['1']}>
                            <Panel header="How long do ban appeals take to process?" key="1">
                                <span>
                                    Ban appeals normally take <strong>2-3 days</strong> to be processed. 
                                    This time frame is not guaranteed as multiple things can impact the appeal, like it being submitted during our peak times (weekends) or the type of appeal.<br />
                                    We do however try and get them completed ASAP, and you need to understand it's not uncommon for us to get 20-30 appeals daily, and as such your appeal isn't going to be the only one we have, so please be patient.
                                </span>
                            </Panel>

                            <Panel header="When do I need to appeal?" key="3">
                                <span>
                                    You can submit an appeal at any time if you feel your ban is invaild / unjustified, 
                                    however you may have not served the correct period of time off the server if you choose to submit your appeal early.<br />
                                    If this is the case, you will be give a re-appeal date at which you can submit a new ban appeal.
                                </span>
                            </Panel>

                            <Panel header="The server says I am banned but this site says I am not. Why?" key="2">
                                <span>
                                    This could happen for a few reasons.

                                    <br />

                                    <br />

                                    If the server says something along the lines of <strong>"disconnected: EAC: Authentication timed out (1/2)"</strong> or authenticate with the game's Anti-Cheat.
                                    We cannot help with this issue, but we suggest making sure you are launching Rust via Steam, <a target="_blank" rel="noreferrer" href="https://support.steampowered.com/kb_article.php?ref=2037-QEUH-3335">Verifying the integrity of your Rust game files</a> and <a target="_blank" rel="noreferrer" href="https://www.easy.ac/en-us/support/game/guides/installer/">Repairing EAC</a>.<br /><br />

                                    <strong>disconnected: steam auth: gamebanned</strong> - this means Rust's Anti-Cheat (EasyAntiCheat) has banned your Steam account.<br />
                                    We are not responsible for serving and managing these types of bans. You can appeal EAC bans on the <a target="_blank" rel="noreferrer" href="https://www.easy.ac/en-us/support/game/contact/appeal/">EasyAntiCheat website for Rust.</a><br /><br />

                                    <strong>kicked: FlyHack Violation Level</strong> - the server has detected your client moving too fast or moving in a invaild way. Just reconnect and you'll be good<br /><br />

                                    If the server says <strong>"Group Limit Violation. Contact Admins via Discord"</strong> you were banned for
                                    Violating the group limit rules (Only across our group limited servers).
                                    You can not appeal these types of bans, as we do not ban without concrete evidence and these sorts of bans get
                                    lifted automatically after the sentence has been served.
                                    Feel free to ask how long you are banned for via the #admin_support channel.<br /><br />

                                    If all else fails, submit an #admin_support ticket in Discord including your SteamID64 (you can find this at <a target="_blank" rel="noreferrer" href="https://steamid.io">https://steamid.io</a>) explaining that you can not appeal your ban for this reason and one of our staff members will get it sorted
                                </span>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Container>
        );
    }
}
 
export default Knowledgebase;