import React, { useContext } from 'react';
import { Link } from "react-router-dom";

import AuthContext from "../../Context/AuthContext";

import { PageHeader } from "antd";
import { LogoutOutlined } from '@ant-design/icons';

import "./NavBar.css";

export default function NavBar()
{
    const { auth: { user } } = useContext(AuthContext);

    console.log(user);

    return (
        <PageHeader
            className="navbar"    
            title={
                <React.Fragment>
                    <Link to="/">
                        Rust Legends Ban Appeals
                    </Link>
                </React.Fragment>
            }
        >
            {(!user) && (
                <a href="https://appeal-api.rustlegends.com/steam-login">
                    <img 
                        style={{ cursor: "pointer" }}
                        src="https://steamcommunity-a.akamaihd.net/public/images/signinthroughsteam/sits_01.png" 
                        alt="Sign in with Steam" 
                    />
                </a>
            )}

            {user &&
                <>
                    <img
                        src={user.avatar}
                        alt={user.personaname}
                        height="30px"
                        width="30px"
                        style={{ borderRadius: "50%" }}
                    />

                    <span className="ant-dropdown-link">
                        &nbsp;&nbsp; {user.personaname} &nbsp;&nbsp;
                        
                        <a href="https://appeal-api.rustlegends.com/logout">
                            <LogoutOutlined />
                        </a>
                    </span>
                </>
            }
        </PageHeader>
    );
}