import React from 'react';
import { Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";
import { Button, Alert } from "antd";

import AuthContext from "../../Context/AuthContext";

import SubmitAppeal from "./SubmitAppeal";
import CurrentAndPastAppeals from "./CurrentAndPastAppeals";

export default function MyAppeals()
{
    const { auth: { user, ban } } = React.useContext(AuthContext);

    return (
        <Container>
            <Row>
                <Col md="12" style={{ paddingTop: "4%" }}>
                    {user && (  
                        <Row>
                            <Col md="12" className="text-center">
                                <img 
                                    src={user.avatar} 
                                    alt={user.steamid}
                                    height="92px" 
                                    width="92px"
                                    style={{ borderRadius: "50%" }}
                                />

                                <h5 className="pt-4">Welcome {user.personaname} ({user.steamid})</h5>

                                <br />

                                {!ban && (
                                    <p>
                                        You are currently not banned across our servers.&nbsp;&nbsp;
                                        If a server says you are banned, please submit an <strong>#admin_support</strong> ticket in Discord.
                                    </p>
                                )}

                                {(ban && user && user.permBanned) && (
                                    <Button type="primary" danger>
                                        <strong>
                                            You are permanently banned across our servers. You no longer have the ability to appeal.
                                        </strong>
                                    </Button>
                                )}

                                {(ban && user) && !user.permBanned && (
                                    <Alert
                                        message={
                                            <>
                                                <p className="pt-2">You are currently banned for "<strong>{ban.reason}</strong>"</p>

                                                <hr />

                                                {(user.nextAppeal && user.nextAppeal > Date.now()) && (
                                                    <Button type="primary">
                                                        You can submit another ban appeal at {new Date(user.nextAppeal).toLocaleString()}
                                                    </Button>
                                                )}

                                                {(!user.nextAppeal || user.nextAppeal < Date.now()) && (
                                                    <SubmitAppeal />
                                                )}
                                            </>
                                        } 
                                        type="info" 
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                    
                    {!user && (
                        <>
                            <h3>Welcome</h3>

                            <Row>
                                <Col md="6" className="mx-auto text-center">
                                    <a style={{ fontSize: "20px" }} href="https://appeal-api.rustlegends.com/steam-login">
                                        <img 
                                            src="https://i.imgur.com/jTrKo05.png" 
                                            alt="Sign in with Steam"
                                            height="200px"
                                            width="200px"
                                        />

                                        <br />
                                        <br />

                                        <span>Appeal Your Ban</span>
                                    </a>
                                </Col>

                                <Col md="6" className="mx-auto text-center">
                                    <Link style={{ fontSize: "20px" }} to="/knowledge-base">
                                        <img 
                                            src="https://i.imgur.com/2KN31YB.png" 
                                            alt="Knowledge base"
                                            height="200px"
                                            width="150px"
                                        />
                                    
                                        <br />
                                        <br />

                                        <span>Knowledgebase</span>
                                    </Link>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
            </Row>

            {user && <CurrentAndPastAppeals />}
        </Container>
    );
}
